<template>
  <NLoadingBarProvider :loading-bar-style="loadingBarStyle" >
    <LoadingBar />
    <NMessageProvider :placement="'top-right'">
      <Message />
      <slot></slot>
    </NMessageProvider>
  </NLoadingBarProvider>
</template>

<script setup>
import { NLoadingBarProvider, NMessageProvider } from "naive-ui";
import LoadingBar from "./LoadingBar.vue";
import Message from "./Message.vue";
const loadingBarStyle = {
  loading: { backgroundColor: "#e1b16a" }
};
</script>

<style lang="scss">
:root {
  --n-color-loading: #e1b16a;
}
</style>
