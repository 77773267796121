import { ref, nextTick } from "vue";
import { query } from "gql-query-builder";
import HttpClient from "project-blue-http-client";
import { GQL_QUERIES } from "../../utils/APIs";

export const PUBLIC_ARTICLES = ref(null);
export const inProgress = ref(false);
export const reqSignal = ref(new AbortController());
export const articleRequestPercentage = ref(0);

export const fields = [
  "id",
  "name",
  "publishDate",
  "authorName",
  "category",
  "countries",
  "companies",
  "elements",
  "products",
  "description",
  "feed",
  "opinion",
  "bannerImage",
  "externalUrl",
  "type",
  "authorAlias"
];

export const getPublicArticles = async () => {
  const gqlQuery = GQL_QUERIES.GET_PUBLIC_ARTICLES;
  reqSignal.value.abort();
  reqSignal.value = new AbortController();
  inProgress.value = true;
  articleRequestPercentage.value = 0;
  
  let client = new HttpClient(gqlQuery.baseUrl);
  let uploadInterval = null;
  let downloadInterval = null;
  client.get(`${gqlQuery.controller}/${gqlQuery.operation}`, {
    onUploadProgress: (progressEvent) => {
      const total = progressEvent.event.total;
      const loaded = progressEvent.event.loaded;

      if (total) {
        if (uploadInterval) {
          clearInterval(uploadInterval);
          uploadInterval = null;
        }
        articleRequestPercentage.value = Math.floor((loaded / total) * 50);
      } else {
        if (!uploadInterval) {
          uploadInterval = setInterval(() => {
            if (articleRequestPercentage.value < 50) {
              articleRequestPercentage.value += 1;
            }
          }, 50);
        }
      }
    },
    onDownloadProgress: (progressEvent) => {
      const total = progressEvent.event.total;
      const loaded = progressEvent.event.loaded;
      if (total) {
        if (downloadInterval) {
          clearInterval(downloadInterval);
          downloadInterval = null;
        }
        articleRequestPercentage.value = Math.min(Math.floor((loaded / total) * 50) + 50, 98);
      } else {
        if (!downloadInterval) {
          downloadInterval = setInterval(() => {
            if (articleRequestPercentage.value < 80) {
              articleRequestPercentage.value += 1;
            }
          }, 50);
        }
      }
    }
  })
  .then(response => {
    if (uploadInterval) clearInterval(uploadInterval);
    if (downloadInterval) clearInterval(downloadInterval);
    const data = response;
    PUBLIC_ARTICLES.value = data;
    nextTick(() => {
      articleRequestPercentage.value = 100; 
    });
    setTimeout(() => {
      inProgress.value = false;
      if (window.$loading) {
        window.$loading.finish();
      }
      articleRequestPercentage.value = 0;
    }, 500);
  })
  .catch(error => {
    inProgress.value = false;
    if (uploadInterval) clearInterval(uploadInterval);
    if (downloadInterval) clearInterval(downloadInterval);
    articleRequestPercentage.value = 0;
  });
};

export const changeColor = (hex, alpha) => {
  const [r, g, b] = hex.match(/\w\w/g).map(x => parseInt(x, 16));
  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};
