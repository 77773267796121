import { GQL_MUTATION } from "../utils/APIs"
import { mutation, query } from "gql-query-builder";
import { ref, reactive } from "vue";
import HttpClient from "project-blue-http-client";

export const reqSignal = ref(new AbortController());
export const inProgress = ref(false);

export const ASSET_PROFILE_TYPE = "ASSET_PROFILE";
export const COMPANY_PROFILE_TYPE = "COMPANY";
export const COUNTRY_PROFILE_TYPE = "COUNTRY";
export const SUBSCRIPTION_FILES_PROFILE_TYPE = "SUBSCRIPTION_FILES_PROFILE_TYPE";

export const openNotification = async (id) => {
    const gqlQuery = GQL_MUTATION.OPEN_NOTIFICATION;
    inProgress.value = true;

    let client = new HttpClient(gqlQuery.baseUrl);
    await client.get(`${gqlQuery.controller}/${gqlQuery.operation}/${id}`)
        .then(() => inProgress.value = false);
}
