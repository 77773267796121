<template>
  <div class="card proxima-bg-light" style="max-height: 98%">
    <div class="p-1">
      <div class="text-start proxima-bg-light p-1">
        <h3 class="card-title text-start">My Subscriptions:</h3>
      </div>
      <div v-if="inProgress" class="col-12 mt-1 d-flex justify-content-center" style="height: 300px; align-items: center;">
        <NProgress
          style="margin: 8px 8px;"
          type="circle"
          :percentage="subscriptionRequestPercentage"
          :color="'#192e40'"
          :rail-color="changeColor('#192e40', { alpha: 0.2 })"
          :indicator-text-color="'#192e40'"
        />
      </div>
      <div v-else class="proxima-bg-light card-body overflow-auto p-1" style="height: 300px">
        <div v-if="subscriptionPackages?.length">
          <div class="col-12" v-for="(nth, i) in subscriptionPackages" :key="i">
            <div class="row g-1">
              <div class="col-12 col-sm-8">
                <button @click="goToCommodity(nth)" style="text-align: start"
                  class="btn text-white proxima-subs-btn badge ellipsis-text" :class="nth.isActive
                    ? 'proxima-subs-blue-button'
                    : 'proxima-subs-grey-button disabled'
                  ">
                  <span v-if="!COST_SERVICES?.includes(nth.element) &&
                    !MONTHLY_SERVICES?.includes(nth.element) &&
                    nth.element !== CRITICAL_MATERIALS_NAME
                  ">{{ nth.elementName }}</span>
                  <span v-else>{{ nth.elementName }}</span>
                </button>
              </div>

              <div class="col-12 col-sm-4">
                <button @click="goToCommodity(nth)" class="btn text-white proxima-subs-btn badge ellipsis-text"
                  style="text-align: start" :class="nth.isActive
                    ? 'proxima-subs-blue-button'
                    : 'proxima-subs-grey-button disabled'
                  ">
                  <span v-if="!nth.isActive">
                    Expiry date: {{ nth.endDate }}
                  </span>
                  <span v-else> Expired: {{ nth.endDate }} </span>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div v-else class="col-12 mt-1 d-flex justify-content-center">
          <h5>No current subscriptions.</h5>
        </div>
      </div>
    </div>
    <div class="p-2 text-start">
      <p class="mt-0">
        The Last login was on
        {{ moment(NOTIFICATIONS.lastLogin).format("YYYY-MM-DD") }}
      </p>
    </div>
  </div>
</template>

<script setup>
import moment from "moment";
import { computed, onMounted, ref } from "vue";
import { ROUTES } from "../../routes/names";
import {
  SUBSCRIPTION_INFO,
  NOTIFICATIONS,
  getSubscriptionInfo,
  inProgress,
  subscriptionRequestPercentage,
  changeColor
} from "../../composables/subscription";
import { useRouter } from "vue-router";
import {
  getUserFiles,
  USER_FILES,
  COST_SERVICES,
  MONTHLY_SERVICES,
  CRITICAL_MATERIALS_NAME,
  MARKET_SERVICE_FILE,
  COST_SERVICE_FILE
} from "../../composables/files/list";
import { sortBy } from "lodash";
import { NProgress } from "naive-ui";

const router = useRouter();

const currentDate = computed(() => {
  const today = new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, "0");
  const day = String(today.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
});

const subscriptionPackages = computed(() => {
  let result = [];

  let subscriptionServices =
    USER_FILES.value?.marketServices?.map((r) => {
      return {
        element: r.element,
        name: r.name,
        serviceType: r.serviceType
      };
    }) || [];

  SUBSCRIPTION_INFO.subscriptionPackages?.forEach((pkg) => {
    const endDate = pkg.effectiveDate != null ? pkg.effectiveDate[1] : new Date();

    result.push({
      element: pkg.id,
      endDate: endDate,
      elementName: pkg.packageName,
      serviceType: pkg.serviceType,
      isActive : pkg?.isActive ?? false
    });
  });

  return result;

  // Extract the element values from services
  const serviceElements = new Set(
    subscriptionServices.map((service) => service.element)
  );

  // Filter result based on the extracted element values
  const filteredMysubs = result.filter((sub) =>
    serviceElements.has(sub.element)
  );

  if (COST_SERVICES.value.length) {
    return sortBy(filteredMysubs, "elementName");
  }
});

const goToCommodity = (nth) => {
  if (nth === "Rare Earths") nth = "REE";
  if (nth.serviceType === "Marketing") {
    router.push({ name: ROUTES.FILES.name, query: { element: nth.element } });
  } else if (nth.serviceType === "CostServices") {
    router.push({ name: ROUTES.COST_ANALYSIS.name, query: { element: nth.element } });
  } else if (nth.serviceType === "Element") {
    router.push({ name: ROUTES.PROXIMA_PROFILES.name, query: { element: nth.element } });
  } else {
    router.push({ name: ROUTES.PAGE_NOT_FOUND.name });
  }

};

onMounted(async () => {
  if (!USER_FILES.value?.files) await getUserFiles();
  if (!SUBSCRIPTION_INFO.subscriptionPackages) await getSubscriptionInfo();
});
</script>

<style lang="scss" scoped>
@import url("../../assets/styles/home-page-styles.scss");

.ellipsis-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}


@media (max-width: 382px) {
  .ellipsis-text {
    width: 90%;
  }
}
</style>
