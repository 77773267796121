import { reactive, ref, computed, nextTick } from "vue";
import { query } from "gql-query-builder";
import { sortBy } from "lodash";
import HttpClient from "project-blue-http-client";
import { GQL_QUERIES } from "../../utils/APIs";
import {
  LAST_SELECTED_ELEMENT_TO_VIEW,
  LAST_SELECTED_PACKAGE_TO_VIEW,
  COUNTRY_TO_VIEW,
  COMPANY_TO_VIEW,
  ASSET_TO_VIEW,
  STATUS_TO_VIEW,
  TYPE_TO_VIEW,
  PRODUCT_GROUP_TO_VIEW,
  PRODUCT_TO_VIEW
} from "../generics";

export const PROFILES_FILTERS = reactive({
  assets: [],
  countries: [],
  companies: [],
  types: [],
  status: [],
  stages: [],
  productGroups: [],
  products: [],
});
export const PUBLISHED_PROFILES = ref({});
export const PUBLISHED_ALL_PROFILES = ref({});
export const inProgress = ref(false);
export const reqSignal = ref(new AbortController());
export const publishedProfilesRequestPercentage = ref(0);

export const ASSETS_OPTS = computed(() => {
  const assets = [
    ...new Map(
      (PUBLISHED_PROFILES.value?.assetProfiles || []).map((nth) => [
        nth.uuid,
        nth,
      ])
    ).values(),
  ];
  const opts = assets.map((nth) => {
    return {
      label: nth.shortName || nth.name,
      value: nth.uuid,
    };
  });
  return sortBy(opts, "label");
});
export const COUNTRIES_OPTS = computed(() => {

  let opts = (PUBLISHED_PROFILES?.value?.countryProfiles || [])
    .map(x => ({
      label: x.country_Name,
      value: x.country_Code
    }));

  let distinctObjects = [
    ...new Map((opts || []).map(item => [JSON.stringify(item), item])).values()
  ];

  let sorted = sortBy(distinctObjects, "label");
  return sorted;
});
export const COMPANIES_OPTS = computed(() => {
  const companies = [
    ...new Map(
      (PUBLISHED_PROFILES.value?.companyProfiles || []).map((nth) => [
        nth.companyId,
        nth,
      ])
    ).values(),
  ];
  const opts = companies
    .filter((nth) => nth.companyId)
    .map((nth) => {
      return {
        label: nth.companyName,
        value: nth.companyId,
      };
    });
  return sortBy(opts, "label");
});
export const TYPES_OPTS = computed(() => {
  const tmp = [
    ...new Set(
      (PUBLISHED_PROFILES.value?.assetProfiles || []).map((nth) => nth.type)
    ),
  ];
  const opts = tmp
    .filter((type) => type?.length > 0)
    .map((type) => {
      return {
        label: type,
        value: type,
      };
    });
  return sortBy(opts, "label");
});
export const STATUS_OPTS = computed(() => {
  const tmp = [
    ...new Set(
      (PUBLISHED_PROFILES.value?.assetProfiles || []).map((nth) => nth.status)
    ),
  ];
  const opts = tmp
    .filter((status) => status?.length > 0)
    .map((status) => {
      return {
        label: status,
        value: status,
      };
    });
  return sortBy(opts, "label");
});
export const STAGES_OPTS = computed(() => {
  const tmp = [
    ...new Set(
      (PUBLISHED_PROFILES.value?.assetProfiles || []).map((nth) => nth.stage)
    ),
  ];
  const opts = tmp
    .filter((stage) => stage?.length > 0)
    .map((stage) => {
      return {
        label: stage,
        value: stage,
      };
    });
  return sortBy(opts, "label");
});
export const PRODUCT_OPTS = computed(() => {
  const tmp = {};
  for (const profile of PUBLISHED_PROFILES.value?.assetProfiles || []) {
    for (const prod of profile?.products || []) {
      tmp[prod.productId] = {
        label: prod.productName,
        value: prod.productId,
      };
    }
  }
  const opts = Object.values(tmp);
  return sortBy(opts, "label");
});


export const getPublishedProfiles = async () => {
  const gqlQuery = GQL_QUERIES.GET_SUBSCRIBED_ASSET_PROFILES;
  reqSignal.value.abort();
  reqSignal.value = new AbortController();
  inProgress.value = true;

  let data = {
    element: LAST_SELECTED_ELEMENT_TO_VIEW.value,
    assets: ASSET_TO_VIEW.value,
    countries: COUNTRY_TO_VIEW.value,
    companies: COMPANY_TO_VIEW.value,
    types: TYPE_TO_VIEW.value,
    status: STATUS_TO_VIEW.value,
    stages: PROFILES_FILTERS.stages,
    productGroups: PRODUCT_GROUP_TO_VIEW.value,
    products: PRODUCT_TO_VIEW.value,
    subscriptionPackages : LAST_SELECTED_PACKAGE_TO_VIEW.value
  }

  let client = new HttpClient(gqlQuery.baseUrl);
  let uploadInterval = null;
  let downloadInterval = null;

  client.post(`${gqlQuery.controller}/${gqlQuery.operation}`, data, {
     onUploadProgress: (progressEvent) => {
      const total = progressEvent.event.total;
      const loaded = progressEvent.event.loaded;

      if (total) {
        if (uploadInterval) {
          clearInterval(uploadInterval);
          uploadInterval = null;
        }
        publishedProfilesRequestPercentage.value = Math.floor((loaded / total) * 50);
      } else {
        if (!uploadInterval) {
          uploadInterval = setInterval(() => {
            if (publishedProfilesRequestPercentage.value < 50) {
              publishedProfilesRequestPercentage.value += 1;
            }
          }, 50);
        }
      }
    },
    onDownloadProgress: (progressEvent) => {
      const total = progressEvent.event.total;
      const loaded = progressEvent.event.loaded;
      if (total) {
        if (downloadInterval) {
          clearInterval(downloadInterval);
          downloadInterval = null;
        }
        publishedProfilesRequestPercentage.value = Math.min(Math.floor((loaded / total) * 50) + 50, 98);
      } else {
        if (!downloadInterval) {
          downloadInterval = setInterval(() => {
            if (publishedProfilesRequestPercentage.value < 80) {
              publishedProfilesRequestPercentage.value += 1;
            }
          }, 50);
        }
      }
    }
  })
  .then(response => {
    if (uploadInterval) clearInterval(uploadInterval);
    if (downloadInterval) clearInterval(downloadInterval);
    PUBLISHED_PROFILES.value = response;
    nextTick(() => {
      publishedProfilesRequestPercentage.value = 100; 
    });
    setTimeout(() => {
      inProgress.value = false;
      if (window.$loading) {
        window.$loading.finish();
      }
      publishedProfilesRequestPercentage.value = 0;
    }, 500);
  })
  .catch(error => {
    if (uploadInterval) clearInterval(uploadInterval);
    if (downloadInterval) clearInterval(downloadInterval);
    if (window.$loading) {
      window.$loading.error();
    }
    inProgress.value = false;
    publishedProfilesRequestPercentage.value = 0; 
  });
};

export const getAllPublishedProfiles = async (element) => {
  const gqlQuery = GQL_QUERIES.GET_SUBSCRIBED_ASSET_PROFILES;
  reqSignal.value.abort();
  reqSignal.value = new AbortController();
  inProgress.value = true;

  let client = new HttpClient(gqlQuery.baseUrl);
  client.post(`${gqlQuery.controller}/${gqlQuery.operation}`, {})
    .then(response => {
      PUBLISHED_ALL_PROFILES.value = response;
      inProgress.value = false;
    });
};

export const changeColor = (hex, alpha) => {
  const [r, g, b] = hex.match(/\w\w/g).map(x => parseInt(x, 16));
  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};