import { controlledComputed } from "@vueuse/core";
import { getAssetProfile } from "../composables/profiles/asset";
import { getPublicCountries } from "../composables/profiles/country";

import { ARTICLES_URL, ASSETS_URL, AUTHENTICATION_URL, COUNTRY_PROFILES_URL, NOTES_URL, FILES_URL } from "../utils/constants";
import CompanyProfile from "../components/Profiles/CompanyProfile.vue";

export const usage = { usage: ["uuid", "type"] };
export const profiles = {
  profiles: [
    "accessControls",
    "alternativeNames",
    "assignedTo",
    "attachmentLinks",
    "byProductCommodities",
    "changes",
    "commissioned",
    "commodities",
    "coordinates",
    "costed",
    "country",
    "countryName",
    "created",
    "createdBy",
    "editedBy",
    "feedStock",
    "geologicalRegion",
    "id",
    "installationType",
    "installationYear",
    "lom",
    "modified",
    "name",
    "nameShort",
    "nearestTown",
    "nonCommercialCommodities",
    "operatorId",
    "operatorName",
    "pinAccuracy",
    "profile",
    "projects",
    "province",
    "reservesNotes",
    "reservesTotalTons",
    "reservesTotalTonsUnit",
    "resourcesNotes",
    "resourcesTotalTons",
    "resourcesTotalTonsUnit",
    "sourceLinks",
    "stage",
    "status",
    "streamType",
    "totalTonsIndicated",
    "totalTonsInferred",
    "totalTonsMeasured",
    "totalTonsProbable",
    "totalTonsProven",
    "totalTonsReserves",
    "totalTonsResources",
    "type",
    "uuid",
    "versions",
    "OEMSuppliers",
  ]
}
export const profiles2 = {
  profiles: [
    "associatedCompanies",
    "changes",
    "company",
    "companyName",
    "companyOverview",
    "coordinates",
    "created",
    "createdBy",
    "editedBy",
    "headquarterCountry",
    "headquarterCountryName",
    "miningCommodities",
    "modified",
    "ownerType",
    "ownershipImage",
    "pinAccuracy",
    "products",
    "refiningProducts",
    "uuid",
    "versions",
    "website",
    { commodityOverview: ["commodity", "links", "overview"] },
    { stockExchanges: ["code", "stockExchange", "stocks",] },
    { ownership: ["id", "percentage"] }
  ]
}

export const getCountryProfilesFields = {
  profiles: [
    "attachmentLinks",
    "changes",
    "commodity",
    "country",
    "countryName",
    "created",
    "editedBy",
    "geology",
    "modified",
    "other",
    "ppd",
    "production",
    "sourceLinks",
    "uuid",
    "versions",
  ]
}

export const getAssetProfileFields = [
  "OEMSuppliers",
  "accessControls",
  "alternativeNames",
  "assignedTo",
  "attachmentLinks",
  "byProductCommodities",
  "changes",
  "commissioned",
  "commodities",
  "coordinates",
  "costed",
  "country",
  "countryName",
  "created",
  "createdBy",
  "editedBy",
  "feedStock",
  "geologicalRegion",
  "id",
  "installationType",
  "installationYear",
  "lom",
  "modified",
  "name",
  "nameShort",
  "nearestTown",
  "nonCommercialCommodities",
  "operatorId",
  "operatorName",
  "pinAccuracy",
  "profile",
  "projects",
  "province",
  "reservesNotes",
  "reservesTotalTons",
  "reservesTotalTonsUnit",
  "resourcesNotes",
  "resourcesTotalTons",
  "resourcesTotalTonsUnit",
  "sourceLinks",
  "stage",
  "status",
  "streamType",
  "totalTonsIndicated",
  "totalTonsInferred",
  "totalTonsMeasured",
  "totalTonsProbable",
  "totalTonsProven",
  "totalTonsReserves",
  "totalTonsResources",
  "type",
  "uuid",
  "versions"
]

export const getPublicSupplyDataFields = [
  "assetProfile",
  "assetProfileName",
  "company",
  "companyName",
  "country",
  "countryName",
  "element",
  "elementName",
  "id",
  "product",
  "productName",
  "stage",
  "status",
  "type",
  "units",
  { yearlyData: ["value", "year"] }
]

export const getPublicCountriesFields = ["code", "name", { publishDetails: ["element", "uuid"] }, "region"]

export const publishDetails = { publishDetails: ["elements", "uuid"] }

export const getGenCoutryProfFields = [
  "banner",
  "countryCode",
  "countryName",
  "id",
  "overview",
  "policies",
  "uuid"
]

export const GQL_QUERIES = {
  GET_SUBSCRIPTION_DETAILS: {
    controller: "user",
    baseUrl: AUTHENTICATION_URL,
    operation: "getSubscriptionDetails",
  },
  GET_SUBSCRIBED_ASSET_PROFILES: {
    baseUrl: AUTHENTICATION_URL,
    controller: "user",
    operation: "getSubscribedProfiles",
    name: "GetPublishedProfilesInput",
  },
  GET_SUBSCRIBED_ASSET_PROFILE: {
   baseUrl : ASSETS_URL,
    controller : "asset",
    operation: "getAssetProfile",
    input: "GetPublishedAssetProfileInput",
  },
  GET_SUBSCRIBED_COMPANY_PROFILE: {
    baseUrl: ASSETS_URL,
    controller: "company",
    operation: "getCompanyProfile",
    input: "GetPublishedCompanyProfileInput",
  },
  GET_SUBSCRIBED_COUNTRY_PROFILE: {
    baseUrl: COUNTRY_PROFILES_URL,
    controller: "country",
    operation: "getCountryProfile",
    input: "GetPublishedCountryProfileInput",
  },
  GET_PUBLIC_COUNTRIES: {
    baseUrl : COUNTRY_PROFILES_URL,
    controller : "country",
    operation: "getCountryProfiles",
    input: "GetPublicDataByElementInput",
    fields: getPublicCountriesFields
  },
  GET_USER_NOTES: {
    baseUrl: AUTHENTICATION_URL,
    controller: "user",
    operation: "getUserNotes",
    input: "GetUserNotesInput",
  },
  GET_USER_NOTE: {
    operation: "getUserNote",
  },
  GET_USER_FILES: {
    // operation: "getSubscribedFiles",
    baseUrl: FILES_URL,
    controller: "files",
    // operation: "getPublicArticles",
    operation: "getSubscribedFiles",
  },
  GET_FILE: {
    // operation: "getSubscribedFiles",
    baseUrl: FILES_URL,
    controller: "files",
    // operation: "getPublicArticles",
    operation: "getFile",
  },
  GET_STREAMS: {
    operation: "getSubscribedStreams",
  },
  GET_PUBLIC_ARTICLES: {
    baseUrl: ARTICLES_URL,
    controller: "article",
    // operation: "getPublicArticles",
    operation: "listPublicArticles",
  },
  GET_COMMODITIES: {
    operation: "getCommodities",
  },
  GET_WORLD_TRADE_DATA: {
    operation: "getCleanWorldTradeData",
    input: "GetWorldTradeDataInput",
  },
  GET_NOTIFICATIONS: {
    baseUrl: AUTHENTICATION_URL,
    controller: "user",
    operation: "getProfilesNotifications",
    fields: [
      "id",
      "type",
      "key",
      "name",
      "seen",
      "event",
      "commodities",
      "created",
    ],
  },
  GET_ALL_FILES: {
    operation: "getAllFiles",
    fields: [],
  },
  GET_MAP_DATA: {
    baseUrl: ASSETS_URL,
    controller: "asset",
    operation: "getMappedAssetProfiles",
    input: "GetMappedAssetProfilesInput",
    fields: [
      { marks: ["id", "type", "status", "coordinates"] },
      { elements: ["code", "name"] },
    ],
  },
  GET_COMPANIES: {
    baseUrl: ASSETS_URL,
    controller: "company",
    operation: "getCompanies",
    input: "GetPublicDataByElementInput",
    fields: ["id", "name", "hasProfile", publishDetails, usage],
  },
  GET_COUNTRIES: {
    operation: "getPublicCountries",
    input: "GetPublicDataByElementInput",
    fields: ["code", "name", "region", publishDetails, usage],
  },



  GET_ASSET_PROFILES: {
    operation: "getAssetProfiles",
    input: "GetAssetProfilesInput",
    fields: ["count", profiles],
  },
  GET_COMPANY_PROFILES: {
    operation: "getCompanyProfiles",
    input: "GetCompanyProfilesInput",
    fields: [
      "count",
      profiles2
    ]
  },
  GET_COUNTRY_PROFILES: {
    baseUrl: COUNTRY_PROFILES_URL,
    controller: "country",
    operation: "listCountryProfiles",
    input: "GetCountryProfilesInput",
    fields: ["count", getCountryProfilesFields]
  },
  GET_ASSET_PROFILE: {
    operation: "getAssetProfile",
    fields: [getAssetProfileFields]
  },

  GET_PUBLIC_SUPPLY_DATA: {
    operation: "getPublicSupplyData",
    input: "GetPublicSupplyDataInput",
    fields: getPublicSupplyDataFields
  },
  GET_GENERAL_COUNTRY_PROFILE: {
    baseUrl : COUNTRY_PROFILES_URL,
    controller : "generalCountry",
    operation: "getGeneralCountryProfile",
    input: "GetPublishedCountryProfileInput",
    fields: getGenCoutryProfFields
  }


};

export const GQL_MUTATION = {
  MANAGE_PERSONAL_NOTE: {
    baseUrl : NOTES_URL,
    controller : "usernotes",
    operation: "manageUserNote",
    input: "ManageUserNoteInput",
  },
  DELETE_PERSONAL_NOTE: {
    baseUrl : NOTES_URL,
    controller : "usernotes",
    operation: "delete",
    input: "DeleteUserNoteInput",
  },
  OPEN_NOTIFICATION: {
    baseUrl : AUTHENTICATION_URL,
    controller :"user",
    operation: "openNotification",
  },
  DOWNLOAD_FILE: {
    operation: "downloadSubscribedFile",
    baseUrl: FILES_URL,
    controller: "files",
  },
  EMAIL_FILE: {
    operation: "sendFileToEmail",
    baseUrl: FILES_URL,
    controller: "files",
  },
  INQUIRE_TO_MARKETING: {
    operation: "inquireToMarketing",
    input: "InquireToMarketingInput",
  },
};

export const AUTH = {
  LOGIN: "/authentication/login",
  REGISTER: "/auth/register",
  VALIDATE_TOKEN: "/auth/verify",
  REFRESH_TOKEN: "/auth/refresh",
  LOGOUT: "/auth/logout",
  RESET_PASSWORD: "/user/resetPassword",
};
