<template>
  <div class="py-4 container-fluid main-dashboard">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body p-0">
            <div class="col-12">
              <div class="w-100 px-5 pt-5 mb-lg-3 mb-sm-3">
                <h3 class="text-center">
                  {{
                    SUBSCRIPTION_INFO.fullname
                      ? `Welcome back, ${SUBSCRIPTION_INFO.fullname}`
                      : ""
                  }}
                </h3>
                <br />
                <div class="row">
                  <!-- USER GUIDE SECTION -->
                  <UserGuide/>

                  <!-- MY SUBSCRIPTIONS SECTION -->
                  <div class="col-lg-6 mb-lg-3 mb-sm-3">
                    <MySubscriptions/>
                  </div>

                  <!-- RECENT NOTIFICATION SECTION -->
                 <!-- <div class="col-lg-6 mb-lg-3 mb-sm-3">
                    <Notifications/>
                  </div>-->

                  <!-- LATEST NEWS SECTION -->
                  <div class="col-lg-12 mb-lg-3 mb-sm-3">
                    <LatestNews/>
                  </div>

                  <!-- MARKET SERVICES SECTION -->
                  <div>
                    <MarketServices/>
                  </div>
                </div>
              </div>
            </div>
            <!-- WORLD MAP SECTION -->
            <WorldMap/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onBeforeMount, onMounted} from "vue";
import {
  SUBSCRIPTION_INFO,
  getSubscriptionInfo
} from "../../composables/subscription";
import UserGuide from "../../components/HomePage/UserGuide.vue";
import MySubscriptions from "../../components/HomePage/MySubscriptions.vue";
import Notifications from "../../components/HomePage/Notifications.vue";
import LatestNews from "../../components/HomePage/LatestNews.vue";
import MarketServices from "../../components/HomePage/MarketServices.vue";
import WorldMap from "../../components/HomePage/WorldMap.vue";
onBeforeMount (async () => {
  window.$loading.start();
})
onMounted(async() =>{
  if(SUBSCRIPTION_INFO.value){
    window.$loading.finish();
  }
})
</script>
